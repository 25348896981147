.Parallax {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000px;
    overflow: hidden;
    background-color: #2f3d5d;
    z-index: -1;

    .parallax-layer {
        position: fixed;
        width: 100%;
        height: 925px;
        background-size: auto 1034px;
        background-position: bottom center;
        z-index: -1;

        //GPU
        backface-visibility: hidden;
        transform-style: preserve-3d;
        perspective: 1000px;
    }

    #layer-0 {
        background-image: url("./images/robo1.png");
    }
    #layer-1 {
        background-image: url("./images/robo2.png");
    }
    #layer-2 {
        background-image: url("./images/robo3.png");
    }
    #layer-3 {
        background-image: url("./images/robo4.png");
    }
    #layer-4 {
        background-image: url("./images/robo5.png");
    }
    #layer-5 {
        background-image: url("./images/robo6.png");
    }
    #layer-6 {
        background-image: url("./images/robo7.png");
    }
    #layer-7 {
        background-image: url("./images/robo8.png");
    }
}