@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}
body {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  background-color: #2f3d5d !important;
}
body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/*  Color Pallette */
.txt-clr-orange {
  color: #d6a419;
}
.txt-clr-light-blue {
  color: #becaf1;
}
.bg-light-blue {
  background-color: #becaf1;
}
// All Colors
// Main Color used from ColorSpace - #2F3D5D
// #D6A419
// #BECAF1
// #BAC6E6
// #2F3D5D
// #7C88AD
// #36497A
// #F1F1E6
// #262626
// #627482
// #989C9E
/* Color Pallette */

/* Header */
.header {
  height: 80px;
}
.header1 {
  box-shadow: none;
  height: 80px;
  transition: background-color 0.16s ease 0s, height 0.16s ease 0s;
}
.header2 {
  box-shadow: none;
  height: 64px;
  background: #2f3d5d;
  transition: background-color 0.16s ease 0s, height 0.16s ease 0s;
}
.mobile-header {
  background: #2f3d5d;
  height: 80px;
}
.mobile-header-content {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.toy-word {
  color: #7c88ad;
  border: solid;
  border-color: #7c88ad;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 1.2rem;
}
.navbar-nav a {
  text-decoration: none;
  padding: 6px 15px;
  position: relative;
  border-radius: 20px;
  background-color: #bac6e6;
  justify-content: space-between;
}
.navbar-nav a:hover,
.navbar-nav a.active {
  color: #fff;
  background-color: #fff;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
}
/* Header */

/* Footer */
.footer {
  overflow: hidden;
  background: #262626;
  color: #627482 !important;
  margin-bottom: 0;
  padding-bottom: 0;
}
.bold-text {
  color: #989c9e !important;
}
.footer-social-media {
  width: 4rem;
  line-height: 4rem;
}
.sponsored-logo {
  width: 150px;
}
/* Footer */

/* Floating Action Menu */
.float-action-btn {
  background-color: #d6a419 !important;
  border-color: #d6a419 !important;
}
.float-action-btn-shop {
  background-color: #7c88ad !important;
  border-color: #7c88ad !important;
}
/* Floating Action Menu */
