/* Sections Content */
.welcome-content {
  background-color: #2f3d5d;
  padding-top: 30px !important;
  padding-bottom: 150px !important;
}
.welcome-subtitle {
  font-size: 1.1rem;
  font-weight: 400;
}
.collections-content {
  background-color: #36497a;
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}
.aboutus-content {
  background-color: #2f3d5d;
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}
.aboutus-story {
  font-size: 1.1rem;
  font-weight: 400;
}
.contactus-content {
  background-color: #36497a;
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}
/* Sections Content */

/* Card Animation */
.card {
  border-color: #becaf1 !important;
  border-width: medium !important;
  transition: all !important;

  .card-body {
    background: #becaf1 !important;
  }

  .hover_content {
    overflow: hidden;
    max-height: 0;
    transform: translateY(1em);
    transition: all 0.55s ease;
  }

  &:hover {
    box-shadow: 0 8px 8px -4px rgba(#d6a419, 50%);
    border-color: #d6a419 !important;
    border-width: thick !important;
    transition: all !important;

    .card-body {
      background: #f1f1e6 !important;
    }

    &::before {
      width: 100%;
      opacity: 1;
      transition: opacity 0.5s ease, width 0.5s ease;
      transition-delay: 0;
    }

    &::after {
      width: 0;
      opacity: 0;
      transition: width 0 ease;
    }

    .hover_content {
      max-height: 20em;
      transform: none;
    }
  }
}
/* If the screen size is 1200px wide or more, set the font-size to 80px */
@media (min-width: 1200px) {
  .card-title-font-size {
    font-size: 1.5rem !important;
    font-weight: 400 !important;
  }
}
/* If the screen size is smaller than 1200px, set the font-size to 80px */
@media (max-width: 1199.98px) {
  .card-title-font-size {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
}
/* Card Animation */

/* Desktop layout shop button */
.shop-now {
  background-color: #2f3d5d;
}
.shop-now-btn {
  width: 20% !important;
  border-radius: 40px !important;
  border-color: white !important;
  font-size: 1.5rem !important;
  background-color: white !important;
  display: flex;
  position: relative;
  top: -23em;
}
.pulse-btn1 {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-white 2s infinite;
}

.pulse-btn1.white {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 white;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
/* Desktop layout shop button */

/* Mobile layout shop button */
.shop-now-bottom-btn {
  border-radius: 40px !important;
  background-color: #d6a419 !important;
  font-size: 1rem !important;
  border-color: #d6a419 !important;
}
.pulse-btn2 {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse-orange 2s infinite;
}

.pulse-btn2.orange {
  background: #d6a419;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #d6a419;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
/* Mobile layout shop button */

/* Contact Us Submit Button */
.btn-primary-contact {
  color: black !important;
  background-color: #d6a419 !important;
  border-color: #d6a419 !important;
}
.btn-primary-contact:focus {
  color: black !important;
  background-color: #c09316 !important;
  border-color: #c09316 !important;
  box-shadow: 0 0 0 0.25rem rgb(214 164 25 / 50%) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
}
.btn-primary-contact:hover {
  color: black !important;
  background-color: #c09316 !important;
  border-color: #c09316 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
}
/* Contact Us Submit Button */

/* Collections Shop Submit Button */
.btn-outline-primary {
  color: black !important;
  border-color: #d6a419 !important;
}
.btn-outline-primary:focus {
  color: black !important;
  background-color: #d6a419 !important;
  border-color: #d6a419 !important;
  box-shadow: 0 0 0 0.25rem rgb(214 164 25 / 50%) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
}
.btn-outline-primary:hover {
  color: black !important;
  background-color: #d6a419 !important;
  border-color: #d6a419 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
}
/* Collections Shop Submit Button */
